/* eslint-disable no-console */
import './App.css';
import React, { Suspense, useContext, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntelisysPlausibleProvider from './layouts/IntelisysPlausibleProvider';
import SpinnerLoader from './animations/spinnerLoader';
import useWebSocket from './hooks/useWebSocket';
import handleNotification from './utils/handleNotification';
import setupFcmToken from './utils/firebaseManager';
import { NotificationContext } from './utils/notificationContext';

const LoginOTP = React.lazy(() =>
  import('./components/loginAndSignup/otpLogin')
);
const AdminHome = React.lazy(() =>
  import('./components/admin/adminNavButtons')
);
const UserProfileDashboard = React.lazy(() =>
  import('./components/userProfile/myProfile')
);
const MainDashboard = React.lazy(() =>
  import('./components/dashboard/mainDashboard')
);
const OTPVerify = React.lazy(() =>
  import('./components/loginAndSignup/otpVerify')
);
const ReferAndEarn = React.lazy(() =>
  import('./components/referAndEarn/referAndEarn')
);

const NOTIFICATION_STREAM = `${process.env.REACT_APP_NODE_ENV}_notifications`;

function App() {
  const userStoreSession = useSelector(
    (state) => state.UserSessionReducer.userSessionStoreData
  );
  const userStoreToken = useSelector(
    (state) => state.UserSessionReducer.userTokenStoreData
  );
  const { socket, isConnected } = useWebSocket(userStoreSession);
  const { handleNotificationClick } = useContext(NotificationContext);
  const notificationHandler = useCallback(
    (notification) => {
      handleNotification(notification, handleNotificationClick);
    },
    [handleNotificationClick]
  );

  useEffect(() => {
    if (userStoreSession && userStoreToken) {
      setupFcmToken(userStoreSession, userStoreToken);
    }
    if (socket && isConnected) {
      if (socket.listeners(NOTIFICATION_STREAM).length === 0) {
        socket.on(NOTIFICATION_STREAM, notificationHandler);
      }
    }
    return () => {
      if (socket) {
        socket.off(NOTIFICATION_STREAM, notificationHandler);
      }
    };
  }, [socket, isConnected, userStoreSession]);

  return (
    <>
      <Suspense fallback={<SpinnerLoader isLoading />}>
        <IntelisysPlausibleProvider>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login' component={LoginOTP} />
            </Route>
            <Route exact path='/dashboard' component={MainDashboard} />
            <Route exact path='/admin' component={AdminHome} />
            <Route exact path='/login' component={LoginOTP} />
            <Route exact path='/otp-verify' component={OTPVerify} />
            <Route exact path='/profile' component={UserProfileDashboard} />
            <Route exact path='/refer-and-earn' component={ReferAndEarn} />
          </Switch>
        </IntelisysPlausibleProvider>
      </Suspense>
      <ToastContainer position='top-left' autoClose={3000} hideProgressBar />
    </>
  );
}

export default App;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import UserSessionReducer from './reducers/userSession';

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_REDUX_ENCRYPTION_SECRET_KEY,
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor],
  whitelist: ['UserSessionReducer'],
};

const rootReducer = combineReducers({
  UserSessionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
